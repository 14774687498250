/* :aa wtf??
.styled-scroll {
  width: 6px;
  height: 6px;
  background-color: theme('colors.level.2');
}
*/

/* Chrome, Safari, Edge, Opera */

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */

input[type="number"] {
  -moz-appearance: textfield;
}

*::-webkit-scrollbar {
  height: 0.375rem !important;
  width: 0.375rem !important;
  --tw-bg-opacity: 1;
  background-color: var(--hz-ui-bg-2);
}

*::-webkit-scrollbar-thumb {
  background-color: var(--hz-ui-bg-3) !important;
  --tw-bg-opacity: 1 !important;
  border-radius: 0.25rem !important;
}

iframe body {
  display: none;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-transition: all 5000s ease-in-out 0s;
  transition: all 5000s ease-in-out 0s;
  -webkit-transition-property: background-color, color;
  transition-property: background-color, color;
}

.shadowed-button {
  transform: translate3d(0, 0, 0);
}

hr.horizontal-gradient {
  border: 0;
  height: 1px;
  background-image: linear-gradient(to right, var(--hz-ui-fg-5), var(--hz-ui-fg-4), var(--hz-ui-fg-5));
}

.carousel {
  overflow: hidden;
}

.inner {
  white-space: nowrap;
  transition: transform 0.3s;
}

.carousel-item {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 200px;
  background-color: green;
  color: #fff;
}

.indicators {
  display: flex;
  justify-content: center;
}

.indicators>button {
  margin: 5px;
}

.indicators>button.active {
  background-color: green;
  color: #fff;
}

.carousel {
  display: inline-flex;
  overflow-x: hidden;
  /*  scroll snap is a great feature which will center the image on snap on touch screen devices  */
  scroll-snap-type: x mandatory;
  /* all below will hide the scrollbar on all browsers.    */
  -webkit-overflow-scrolling: touch;
  scrollbar-width: none;
  /* For Firefox */
  -ms-overflow-style: none;
  /* For Internet Explorer and Edge */
}

input::-webkit-input-placeholder {
  line-height: normal !important;
}

body {
  overflow: overlay;
}

input:disabled::-moz-placeholder, textarea:disabled::-moz-placeholder {
  opacity: 1;
  /* 2. correct opacity on iOS */
}

input:disabled,
textarea:disabled,
input:disabled::placeholder,
textarea:disabled::placeholder {
  opacity: 1;
  /* 2. correct opacity on iOS */
}


/*Transition*/
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
.dialog-overlay[data-state='open'],
.dialog-content[data-state='open'] {
  animation: fadeIn 100ms ease-out;
}
.dialog-overlay[data-state='closed'],
.dialog-content[data-state='closed'] {
  animation: fadeOut 100ms ease-in;
}
@font-face {
font-family: '__drukWide_2a62e8';
src: url(/_next/static/media/ae7b7882e4812cfb-s.p.ttf) format('truetype');
font-display: swap;
font-weight: 700;
font-style: normal;
}

@font-face {
font-family: '__drukWide_2a62e8';
src: url(/_next/static/media/5441890ba324a8de-s.p.ttf) format('truetype');
font-display: swap;
font-weight: 500;
font-style: normal;
}@font-face {font-family: '__drukWide_Fallback_2a62e8';src: local("Arial");ascent-override: 66.06%;descent-override: 16.74%;line-gap-override: 1.88%;size-adjust: 154.10%
}.__className_2a62e8 {font-family: '__drukWide_2a62e8', '__drukWide_Fallback_2a62e8'
}.__variable_2a62e8 {--font-druk-wide: '__drukWide_2a62e8', '__drukWide_Fallback_2a62e8'
}

@font-face {
font-family: '__inter_42b0b7';
src: url(/_next/static/media/e744c16fc4f8b964-s.p.ttf) format('truetype');
font-display: swap;
}@font-face {font-family: '__inter_Fallback_42b0b7';src: local("Arial");ascent-override: 89.79%;descent-override: 22.36%;line-gap-override: 0.00%;size-adjust: 107.89%
}.__className_42b0b7 {font-family: '__inter_42b0b7', '__inter_Fallback_42b0b7'
}.__variable_42b0b7 {--font-inter: '__inter_42b0b7', '__inter_Fallback_42b0b7'
}

